import { routeMap } from '/routeMap'
import { mapJobData } from '/machinery/mapJobData'
import { useLanguage } from '/machinery/I18n'
import { makeSlug } from '/machinery/makeSlug'
import { getJobLabels } from '/domain/getJobLabels'

import { HeadingMd } from '/features/buildingBlocks/Heading'
import { ButtonLinkPrimary } from '/features/buildingBlocks/Button'
import { JobCard } from '/features/buildingBlocks/JobCard'

import styles from './Jobs.css'

export function Jobs({ title, jobs, description, button }) {
  const language = useLanguage()

  return (
    <div className={styles.component}>
      <div className={styles.content}>
        <HeadingMd h={3} {...{ title }} />
        {description && <p className={styles.text}>{description}</p>}
      </div>

      <div className={styles.containerJobs}>
        <ul className={styles.jobs}>
          {jobs?.slice(0, 5).map(({ _source: job }) => {
            const mappedJob = mapJobData(job, { language })
            const { id: jobId, title, jobLanguage } = mappedJob
            const labels = getJobLabels(mappedJob, { language })

            return (
              <li key={jobId}>
                <JobCard
                  id={jobId}
                  smallTitle
                  {...{ labels, title }}
                  href={routeMap.app.job({
                    language,
                    jobId,
                    jobTitle: makeSlug({ input: title, language: jobLanguage })
                  })}
                />
              </li>
            )
          })}
        </ul>

        {button?.href && <ButtonLinkPrimary
          href={button.href}
          label={button.label}
          dataX='goto-jobs-overview'
        />}
      </div>
    </div>
  )
}
