import { Icon } from '/features/buildingBlocks/Icon'

import styles from './SocialContact.css'

export function SocialContact({ icon, href, ariaLabel, dataX = undefined }) {
  return (
    <a
      className={styles.component}
      data-x={dataX ? dataX : 'link-to-recruiter-social'}
      target='_blank'
      aria-label={ariaLabel}
      rel="noreferrer"
      {...{ href }}
    >
      <span className={styles.icon}>
        <Icon {...{ icon }} />
      </span>
    </a>
  )
}
